import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';  

// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/bundle';


// import "swiper/css";
// import "swiper/css/bundle";
// import "swiper/swiper-bundle.css";
// import "swiper/css/a11y";
// import "swiper/css/autoplay";
// import "swiper/css/controller";
// import "swiper/css/effect-coverflow";
// import "swiper/css/effect-cube";
// import "swiper/css/effect-fade";
// import "swiper/css/effect-flip";
// import "swiper/css/effect-creative";
// import "swiper/css/effect-cards";
// import "swiper/css/free-mode";
// import "swiper/css/grid";
// import "swiper/css/hash-navigation";
// import "swiper/css/history";
// import "swiper/css/keyboard";
// import "swiper/css/manipulation";
// import "swiper/css/mousewheel";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/parallax";
// import "swiper/css/scrollbar";
// import "swiper/css/thumbs";
// import "swiper/css/virtual";
// import "swiper/css/zoom";

Vue.config.productionTip = false

import VScaleScreen from 'v-scale-screen'

Vue.use(VScaleScreen)

Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
