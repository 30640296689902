<template>
  <div class="scale-screen-container" ref="container" :style="containerStyle">
    <div class="scale-screen-content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>


</template>

<script>
export default {
  name: 'VScaleScreen',
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    autoScale: {
      type: Object,
      default: () => ({ x: true, y: true })
    },
    bodyOverflowHidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scale: 1,
      initialDistance: null,
      isZooming: false,
      transformOriginX: 0,
      transformOriginY: 0,
      initialTouchX: 0,
      initialTouchY: 0,
      isSwiping: false
    };
  },
  computed: {
    containerStyle() {
      return {
        overflow: this.bodyOverflowHidden ? 'hidden' : 'auto',
        position: 'relative',
        width: '100%',
        height: '100%'
      };
    },
    contentStyle() {
      return {
        transform: `scale(${this.scale})`,
        transformOrigin: `${this.transformOriginX}px ${this.transformOriginY}px`,
        width: `${this.width}px`,
        // height: `100%`,
        willChange: 'transform'
      };
    }
  },
  mounted() {
    this.calculateScale();
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener('touchstart', this.handleTouchStart);
    this.$refs.container.removeEventListener('touchmove', this.handleTouchMove);
    this.$refs.container.removeEventListener('touchend', this.handleTouchEnd);
  },
  methods: {
    calculateScale() {
      const container = this.$refs.container;
      const scaleX = container.clientWidth / this.width;
      const scaleY = container.clientHeight / this.height;

      this.scale = Math.min(scaleX, scaleY);
      if (!this.autoScale.x) this.scale = scaleY;
      if (!this.autoScale.y) this.scale = scaleX;
    },
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        this.isZooming = true;
        this.initialDistance = this.getDistanceBetweenTouches(event);
        const centerX = (event.touches[0].pageX + event.touches[1].pageX) / 2;
        const centerY = (event.touches[0].pageY + event.touches[1].pageY) / 2;
        this.updateTransformOrigin(centerX, centerY);
      } else if (event.touches.length === 1) {
        this.isSwiping = true;
        this.initialTouchX = event.touches[0].pageX;
        this.initialTouchY = event.touches[0].pageY;
      }
    },
    handleTouchMove(event) {
      if (this.isZooming) {
        const distance = this.getDistanceBetweenTouches(event);
        const scaleChange = distance / this.initialDistance;
        this.scale *= scaleChange;
        this.initialDistance = distance;
      } else if (this.isSwiping) {
        // 处理滑动逻辑
      }
    },
    handleTouchEnd() {
      this.isZooming = false;
      this.isSwiping = false;
    },
    getDistanceBetweenTouches(event) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const distanceX = touch2.pageX - touch1.pageX;
      const distanceY = touch2.pageY - touch1.pageY;
      return Math.sqrt(distanceX * distanceX + distanceY * distanceY);
    },
    updateTransformOrigin(x, y) {
      const rect = this.$refs.container.getBoundingClientRect();
      this.transformOriginX = x - rect.left;
      this.transformOriginY = y - rect.top;
    }
  }
};
</script>

<style scoped>
.scale-screen-container {
  width: 100%;
  height: 100%;
}

.scale-screen-content {
  position: absolute;
}
</style>
