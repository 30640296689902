import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    base: '/',
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'talentChart',
            component: () => import("@/views/content/home/index.vue")
        },
        {
            path: '/home',
            name: 'home',
            component: () => import("@/views/content/home/index.vue")
        },
        {
            path: '/content/age',
            name: 'age',
            component: () => import("@/views/content/age/index.vue")
        },
        {
            path: '/content/education',
            name: 'education',
            component: () => import("@/views/content/education/index.vue")
        },
        {
            path: '/content/developer',
            name: 'developer',
            component: () => import("@/views/content/developer/index.vue")
        },

        {
            path: '/content/collegiate',  // 15
            name: 'collegiate',
            component: () => import("@/views/content/collegiate/index.vue")
        },
        {
            path: '/content/community', // 19
            name: 'community',
            component: () => import("@/views/content/community/index.vue")
        },
        {
            path: '/content/students', // 18
            name: 'students',
            component: () => import("@/views/content/students/index.vue")
        },
        {
            path: '/content/hrDemand',
            name: 'hrDemand',
            component: () => import("@/views/content/hrDemand/index.vue")
        },
        {
            path: '/content/labour',
            name: 'labour',
            component: () => import("@/views/content/labour/index.vue")
        },
        {
            path: '/content/scientist',
            name: 'scientist',
            component: () => import("@/views/content/scientist/index.vue")

        },
        {
            path: '/content/graduate',
            name: 'graduate',
            component: () => import("@/views/content/graduate/index.vue")

        },
        {
            path: '/content/study',
            name: 'study',
            component: () => import("@/views/content/study/index.vue")
        },
        {
            path: '/content/engineer', // 7
            name: 'engineer',
            component: () => import("@/views/content/engineer/index.vue")
        },
        {
            path: '/content/unemployment',
            name: 'unemployment',
            component: () => import("@/views/content/unemployment/index.vue")
        },
        {
            path: '/content/entrepreneurial',
            name: 'entrepreneurial',
            component: () => import("@/views/content/entrepreneurial/index.vue")
        },
        {
            path: '/content/unicorn',
            name: 'unicorn',
            component: () => import("@/views/content/unicorn/index.vue")
        },
        {
            path: '/content/startupEmployees',
            name: 'startupEmployees',
            component: () => import("@/views/content/startupEmployees/index.vue")
        },
        {
            path: '/content/foreign',
            name: 'foreign',
            component: () => import("@/views/content/foreign/index.vue")
        },
        {
            path: '/content/housingPrice',
            name: 'housingPrice',
            component: () => import("@/views/content/housingPrice/index.vue")
        },
        {
            path: '/content/housingPriceIncomeRatio',
            name: 'housingPriceIncomeRatio',
            component: () => import("@/views/content/housingPriceIncomeRatio/index.vue")
        },
        {
            path: '/content/educationExpenditure',
            name: 'educationExpenditure',
            component: () => import("@/views/content/educationExpenditure/index.vue")
        },
        {
            path: '/content/schoolIncome',
            name: 'schoolIncome',
            component: () => import("@/views/content/schoolIncome/index.vue")
        },
        {
            path: '/content/schoolProject',
            name: 'schoolProject',
            component: () => import("@/views/content/schoolProject/index.vue")
        },
        {
            path: '/content/learnProject',
            name: 'learnProject',
            component: () => import("@/views/content/learnProject/index.vue")
        },
        {
            path: '/content/knownIndustry',
            name: 'knownIndustry',
            component: () => import("@/views/content/knownIndustry/index.vue")
        },
        {
            path: '/content/unicornCard',
            name: 'unicornCard',
            component: () => import("@/views/content/unicornCard/index.vue")
        },
        {
            path: '/content/patent',
            name: 'patent',
            component: () => import("@/views/content/patent/index.vue")
        },
        {
            path: '/content/researchCenter', // 27
            name: 'researchCenter',
            component: () => import("@/views/content/researchCenter/index.vue")

        },
        {
            path: '/content/worldTopSchool',
            name: 'worldTopSchool',
            component: () => import("@/views/content/worldTopSchool/index.vue")
        },
        {
            path: '/content/creativeMajorStudent',
            name: 'creativeMajorStudent',
            component: () => import("@/views/content/creativeMajorStudent/index.vue")
        },

        {
            path: '/content/InnoHK', // 26
            name: 'InnoHK',
            component: () => import("@/views/content/InnoHK/index.vue")
        },
        {
            path: '/content/laboratory',
            name: 'laboratory',
            component: () => import("@/views/content/laboratory/index.vue")
        },
        {
            path: '/content/putInto',
            name: 'putInto',
            component: () => import("@/views/content/putInto/index.vue")

        },
        {
            path: '/content/development',
            name: 'development',
            component: () => import("@/views/content/development/index.vue")
        },


        {
            path: '/content/employeesTotal',
            name: 'employeesTotal',
            component: () => import("@/views/content/employeesTotal/index.vue")
        },
        {
            path: '/content/enterprisesTotal',
            name: 'enterprisesTotal',
            component: () => import("@/views/content/enterprisesTotal/index.vue")
        },
        {
            path: '/content/nationality',
            name: 'nationality',
            component: () => import("@/views/content/nationality/index.vue")
        },
        {
            path: '/content/expenditure',
            name: 'expenditure',
            component: () => import("@/views/content/expenditure/index.vue")
        },
        {
            path: '/content/innovate',
            name: 'innovate',
            component: () => import("@/views/content/innovate/index.vue")
        },
        {
            path: '/content/populationMobility',
            name: 'populationMobility',
            component: () => import("@/views/content/populationMobility/index.vue")
        },
        {
            path: '/content/talentIntroduction',
            name: 'talentIntroduction',
            component: () => import("@/views/content/talentIntroduction/index.vue")
        },
        {
            path: '/content/convenientTransportation',
            name: 'convenientTransportation',
            component: () => import("@/views/content/convenientTransportation/index.vue")
        },
        {
            path: '/content/academicOutput',
            name: 'academicOutput',
            component: () => import("@/views/content/academicOutput/index.vue")
        },
        {
            path: '/content/capitalScale',
            name: 'capitalScale',
            component: () => import("@/views/content/capitalScale/index.vue")
        },
        {
            path: '/content/officeArea',
            name: 'officeArea',
            component: () => import("@/views/content/officeArea/index.vue")
        },
        {
            path: '/content/providePosition',
            name: 'providePosition',
            component: () => import("@/views/content/providePosition/index.vue")
        },
        {
            path: '/content/nationalEngineer',
            name: 'nationalEngineer',
            component: () => import("@/views/content/nationalEngineer/index.vue")
        },
        {
            path: '/content/patentApplication',
            name: 'patentApplication',
            component: () => import("@/views/content/patentApplication/index.vue")
        },
        {
            path: '/content/projectIncome',
            name: 'projectIncome',
            component: () => import("@/views/content/projectIncome/index.vue")
        },
        {
            path: '/content/consultingProjectIncome',
            name: 'consultingProjectIncome',
            component: () => import("@/views/content/consultingProjectIncome/index.vue")
        },
        {
            path: '/content/contractProjectIncome',
            name: 'contractProjectIncome',
            component: () => import("@/views/content/contractProjectIncome/index.vue")
        },
        {
            path: '/content/intellectualProperty',
            name: 'intellectualProperty',
            component: () => import("@/views/content/intellectualProperty/index.vue")
        },
        {
            path: '/content/expenditure',
            name: 'expenditure',
            component: () => import("@/views/content/expenditure/index.vue")
        },
        {
            path: '/content/entrepreneurship',
            name: 'entrepreneurship',
            component: () => import("@/views/content/entrepreneurship/index.vue")
        },
        {
            path: '/content/developmentExpenditure',
            name: 'developmentExpenditure',
            component: () => import("@/views/content/developmentExpenditure/index.vue")
        },
        {
            path: '/content/jointLaboratory',
            name: 'jointLaboratory',
            component: () => import("@/views/content/jointLaboratory/index.vue")
        },

        {
            path: '/content/medicalSystem',
            name: 'medicalSystem',
            component: () => import("@/views/content/medicalSystem/index.vue")
        },

        {
            path: '/content/IntermediateIncome',
            name: 'IntermediateIncome',
            component: () => import("@/views/content/IntermediateIncome/index.vue")

        },
        {
            path: '/content/firms',
            name: 'firms',
            component: () => import("@/views/content/firms/index.vue")
        },
        {
            path: '/content/empower',
            name: 'empower',
            component: () => import("@/views/content/empower/index.vue")
        },
        {
            path: '/content/scientificAwards',
            name: 'empower',
            component: () => import("@/views/content/scientificAwards/index.vue")
        },
        {
            path: '/content/technologyAwards',
            name: 'empower',
            component: () => import("@/views/content/technologyAwards/index.vue")
        },
        {
            path: '/content/areaTotal',
            name: 'empower',
            component: () => import("@/views/content/areaTotal/index.vue")
        },
        {
            path: '/content/practitioner',
            name: 'empower',
            component: () => import("@/views/content/practitioner/index.vue")
        },
        {
            path: '/content/riskInvestment',
            name: 'empower',
            component: () => import("@/views/content/riskInvestment/index.vue")
        },
        {
            path: '/content/fundraising',
            name: 'empower',
            component: () => import("@/views/content/fundraising/index.vue")
        },
        {
            path: '/content/chuangke',
            name: 'empower',
            component: () => import("@/views/content/chuangke/index.vue")
        },
        {
            path: '/content/taxPolicy',
            name: 'empower',
            component: () => import("@/views/content/taxPolicy/index.vue")
        },
        {
            path: '/content/economicCompany',
            name: 'economicCompany',
            component: () => import("@/views/content/economicCompany/index.vue")

        }
    ]
})
