<template>
  <div id="app">
    <v-scale-screen :bodyOverflowHidden="false" width="1920" :height="height" :autoScale="{
      x: true,
      y: false
    }" 
    :wrapperStyle="{
      background: '#fff'
    }">
      <div class="page-content">
        <router-view />
      </div>
    </v-scale-screen>

  </div>
</template>

<script>
  // import VScaleScreen from 'v-scale-screen'
  import VScaleScreen from '@/components/scale.vue';
	export default {
		name: 'App',
    components: {
      VScaleScreen
    },
    data() {
      return{
        width: 1920,
        height: 1080,
      }
    },
    mounted(){
      if (window.screen.height < 1080) {
        this.height = 1920 / window.screen.width * window.screen.height;
      }
      if (window.screen.width<1920 && window.screen.height>1080){
        this.height=1920/window.screen.width*window.screen.height;
      }
      if (window.screen.width<1920){
        this.width=1080/window.screen.height*window.screen.width;
      }
      if (window.screen.width>1920 & window.screen.height>1080){
        this.height=1920/window.screen.width*window.screen.height;
      }
    }
  }
</script>

<style lang="scss">
@import '@/assets/css/public.css';
@import '@/assets/css/theme.scss';

.page-content {
  height: 100%;
  overflow: auto;
}
</style>